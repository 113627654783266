/**
 * 全站http配置
 *
 * axios参数说明
 * isSerialize是否开启form表单提交
 * isToken是否需要token
 */
import axios from 'axios'
import { serialize } from '@/utils/util'
import { getToken } from '@/utils/auth'
import website from '@/config/website'
import { Base64 } from 'js-base64'
import { Toast, Dialog } from 'vant';
import crypto from '@/utils/cryptos';
import router from "@/router/index.js";
import { setStore, getStore, clearStore } from "@/utils/localStorage.js";
// 加密白名单
const whiteList = [
  '/api/blade-resource/oss/endpoint/put-file?code=minio'
]

// 默认超时时间
axios.defaults.timeout = 20000
// 返回其他状态码
axios.defaults.validateStatus = function (status) {
  return status >= 200 && status <= 500
}
// 跨域请求，允许保存cookie
axios.defaults.withCredentials = false

// http request拦截
axios.interceptors.request.use(config => {
  // 开启 progress bar
  const meta = (config.meta || {})
  const isToken = meta.isToken === false
  const loginToken = getStore({ name: "token" });
  config.headers.Authorization = `Basic ${Base64.encode(`${website.clientId}:${website.clientSecret}`)}`

  // 让每个请求携带token
  if (loginToken) {
    if (loginToken == '' || loginToken == null || loginToken == 'undefined') {
      // localStorage.clear()
    } else {
      if (config.url.indexOf('/api/blade-system/wxmenu/loadQWXMenus') == -1) {
        config.headers[website.tokenHeader] = 'bearer ' + loginToken
      }
   
    }

  }

  // headers中配置text请求
  if (config.text === true) {
    config.headers['Content-Type'] = 'text/plain'
  }
  // headers中配置serialize为true开启序列化
  if (config.method === 'post' && meta.isSerialize === true) {
    config.data = serialize(config.data)
  }
  // post请求加密
  let encryption = true;
  whiteList.map(e => {
    if (config.url == e) {
      encryption = false;
    }
  })
  if (encryption && config.data) {
    config.headers['Content-Type'] = 'application/json;charset=UTF-8';
    config.data = crypto.encryptAES(JSON.stringify(config.data), crypto.aesKey);
  }

  return config
}, error => {
  return Promise.reject(error)
})
// http response 拦截
axios.interceptors.response.use(res => {
  // 判断返回是否是加密体,是加密体就解密
  if (typeof res.data === "string") {
    let aesJson = crypto.decryptAES(res.data, crypto.aesKey);
    let modifiedString = aesJson.replace(/:\s*(\d{17,})/g, function (match) {
      return ':"' + match.slice(1) + '"';
    });
    res.data = JSON.parse(modifiedString);
  }
  console.log(res.data,'res')
  Toast.clear();
  // 获取状态码
  const status = res.data.code || res.status
  console.log(status,'status')
  const statusWhiteList = website.statusWhiteList || []
  const message = res.data.msg || res.data.error_description || '网络开小差了'
  if (message == 'Internal Server Error') {
    return
  }
  // 如果在白名单里则自行catch逻辑处理
  if (statusWhiteList.includes(status)) return Promise.reject(res)
  // 如果是401拦截不提示
  if (status == 401) {
    router.push('/login');
  }
  // 如果请求为非200否者默认统一处理
  if (status !== 200) {
    // this.$toast(message);
    // Toast.fail(message)
    return Promise.reject(new Error(message))
  }
  return res
}, error => {
  // NProgress.done()
  return Promise.reject(new Error(error))
})

/**
 * Content-Type: ['application/x-www-form-urlencoded', 'application/json;charset=UTF-8', 'multipart/form-data']
 */
export const post = (url, params = {}, configs = {}, customs = '') => {
  switch (customs) {
    case 'json':
      break
    case 'form':
      params = formatToForm(params)
      break

    default:
      break
  }
  return axios.post(url, params, configs)
}

export const get = (url, params = {}, configs = {}) => {
  const requestConfig = Object.assign({ params }, configs)
  return axios.get(url, requestConfig)
}

export const doDelete = (url, params = {}, customs = 'urlencoded') => {
  switch (customs) {
    case 'json':
      params = { data: params }
      break
    case 'form':
      params = { data: formatToForm(params) }
      break

    default:
      params = { data: params }
      break
  }
  return axios.delete(url, params)
}

function formatToForm(data) {
  const params = new FormData()

  for (const key in data) {
    params.append(key, data[key])
  }

  return params
}

export default axios

// export const headers = {
//   Authorization: `Basic ${Base64.encode(`${website.clientId}:${website.clientSecret}`)}`,
//   [website.tokenHeader]: 'bearer ' + getToken()
// }
const loginToken = localStorage.getItem('access_token');
export const headers = {
  Authorization: `Basic ${Base64.encode(`${website.clientId}:${website.clientSecret}`)}`,
  [website.tokenHeader]: 'bearer ' + loginToken
}
