/**
 * 全局配置文件
 */
export default {
  // corp_id: 1000002,    //测试环境
  corp_id: 1000050,   //正式环境
  // tenantId: 100002,     // 管理组租户编号
  appCode:"HN301",
  key: 'saber', // 配置主键,目前用于存储
  clientId: 'hnwechatwp', // 客户端id
  clientSecret: 'hnwechatwp_secret', // 客户端密钥
  lockPage: '/lock',
  tokenTime: 3000,
  tokenHeader: 'Blade-Auth',
  chatSDKAppID: 1400717065,
  videoSDKAppID: 1400717067,
  // http的status默认放行列表
  statusWhiteList: [],

  // 配置菜单的属性
  menu: {
    iconDefault: 'iconfont icon-caidan',
    props: {
      label: 'name',
      path: 'path',
      icon: 'source',
      children: 'children',
    },
  },

};
