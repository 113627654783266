
// const key = 'token'
// export const setToken = (token) => {
//   sessionStorage.setItem(key, token)
// }
// export const getToken = () => sessionStorage.getItem(key)
// export const removeToken = () => sessionStorage.removeItem(key)

import Cookies from 'js-cookie'
const TokenKey = 'saber-access-token'
const RefreshTokenKey = 'saber-refresh-token'
export function getToken () {
  return Cookies.get(TokenKey)
}

export function setToken (token) {
  return Cookies.set(TokenKey, token)
}

export function getRefreshToken () {
  return Cookies.get(RefreshTokenKey)
}

export function setRefreshToken (token) {
  return Cookies.set(RefreshTokenKey, token)
}

export function removeToken () {
  return Cookies.remove(TokenKey)
}

export function removeRefreshToken () {
  return Cookies.remove(RefreshTokenKey)
}
