<template>
  <div class="container" id="container">
    <router-view></router-view>
  </div>
</template>

<script>
import qs from "qs";
import { setStore, getStore, removeStore } from "@/utils/localStorage.js";
export default {
  data() {
    return {};
  },

  created() {

    localStorage.removeItem( "routerHistory" );
    localStorage.removeItem("tenantId");
    let url = this.$router.history._startLocation.split("?")[0];
    let typeVal = qs.parse(this.$router.history._startLocation.split("?")[1]);
    let newTypeVal = qs.parse(typeVal);
    if (newTypeVal.type) {
      localStorage.setItem( "routerHistory",  url );
    }
    if (newTypeVal.tenantId) {
      localStorage.setItem("tenantId", newTypeVal.tenantId);
      // setStore({ name: "tenantId", content: newTypeVal.tenantId });
    }
  },
  mounted() {
    if (localStorage.getItem("data")) {
      const b = window.localStorage.getItem("data");
      const c = JSON.parse(b);
      const { time } = c;
      const { date } = c;
      if (parseInt(time) + parseInt(date) < parseInt(new Date().getTime())) {
        // 如果 date + time 小于当前时间的时间戳，说明已经超过86400秒(一天)的时效时间
        removeStore({ name: "token" });
        localStorage.removeItem("data");
      }
    } else {
      //      removeStore({ name: "token" });
      // removeStore({ name: "tenantId" });
      // localStorage.removeItem("data");
    }
  },
};
</script>

<style lang="less" scoped>
.container {
  // padding-bottom: 50px;
  background-color: #f4f8fb;
  // padding-bottom: 50px;
  min-height: 100vh;
}
.van-tabbar {
  border-top: 1px solid #f8f8f8;
}
</style> >
