import Vue from 'vue';
import 'amfe-flexible';// 移动端适配
import {
  Toast,
  Cell, Row, Col, Dialog, Divider, Image, Icon, Notify, TreeSelect,
  NavBar, Form, Field, CellGroup, Button, Tabbar, TabbarItem, Search, Card,
  Tag, Popup, Picker, Grid, GridItem, Swipe, SwipeItem, Empty, Loading, List, PullRefresh, ActionSheet,
  Badge, Calendar, cell, NoticeBar, Sidebar, SidebarItem, Step, Steps,
  DatetimePicker, DropdownMenu, DropdownItem, Tab, Tabs, Pagination ,
  Image as VanImage,
} from 'vant';
import 'vant/lib/index.css';
import request, { get, post } from '@/utils/request';
import './assets/fonts/iconfont.js';
import './assets/style/common.less';
import './assets/icons/iconfont.css';
import './assets/icons/iconfont.js';
import axios from 'axios';
import App from './App.vue';
import router from './router';
import store from './store';
import Vconsole from 'vconsole'

window.addEventListener('online', () => {
  Notify.clear();
  Notify({ type: 'success', message: '网络已连接' });
});
window.addEventListener('offline', () => {
  Notify({ type: 'danger', message: '网络断开了', duration: 0 });
});
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(List);
Vue.use(Cell);
Vue.use(Row);
Vue.use(Col);
Vue.use(Dialog);
Vue.use(Image);
Vue.use(Icon);
Vue.use(TreeSelect);
Vue.use(Divider);

Vue.prototype.$axios = axios;

Vue.use(ActionSheet);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Step);
Vue.use(Steps);
Vue.use(Sidebar);
Vue.use(SidebarItem);
Vue.use(List);
Vue.use(NoticeBar);
Vue.use(Tag);
Vue.use(Calendar);
Vue.use(cell);
Vue.use(DatetimePicker);
Vue.use(Badge);
Vue.use(Card);
Vue.use(Search);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Button);
Vue.use(NavBar);
Vue.use(Form);
Vue.use(Field);
Vue.use(CellGroup);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Empty);
Vue.use(Loading);
Vue.use(Toast);
Vue.use(PullRefresh);
Vue.use(Notify);
Vue.use(Pagination);
Vue.use(VanImage);
Vue.use(Icon);
Vue.config.productionTip = false;

Vue.prototype.$request = request;
Vue.prototype.$get = get;
Vue.prototype.$post = post;

// let vConsole = new Vconsole()

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
