// 统一封装接公共（登录）口方法
import request from '@/router/axios';

// 构造网页授权链接
export const getCodeAPI = (appId, tenantId) => request({
  url: `/api/blade-auth/wecom/getCode`,
  method: 'get',
  params: {
    appCode: appId,
    tenantId,
  },

});
export const getUserAPI = (appId, code) => request({
  url: `/api/blade-auth/wecom/getUserInfo`,
  method: 'get',
  params: {
    appCode: appId,
    code,
  },

});

//获取医院数据
export const selectHospitolAPI = () => request({
  url: `/api/blade-system/app/getAppTententList?appCode=HN301`,
  method: 'get',


});




// 获取手机验证码
export const getPhoneCodeAPI = (phone, id) => request({
  // url: `/api/blade-auth/wecom/wechat/getVerifyCode`,
  url: `/api/blade-resource/sms/endpoint/send-sms-message-phone`,
  method: 'get',
  params: {
    phone,
    tenantId: id,
  },

});




export const loginAPI = (tenantId, phone, verifyCode, type, code, key, deptId, roleId) => request({
  url: '/api/blade-auth/oauth/token',
  method: 'post',
  headers: {
    'Captcha-Key': 'saber',
    'Captcha-Code': code,
    'Tenant-Id': tenantId,
    'Dept-Id': deptId,
    'Role-Id': roleId,

  },
  params: {
    tenantId,
    phone,
    verifyCode,
    grant_type: 'wecom',
    // grant_type: 'captcha',
    scope: 'all',
    type,
  },
});

// 菜单列表
export const getMenuList = () => request({
  // url:'/api/blade-system/wxmenu/loadMenus',
  url: '/api/blade-system/wxmenu/loadQWXMenus',
  method: 'get',
 
});

// 验证码
export const getCaptcha = () => request({
  url: '/api/blade-auth/oauth/captcha',
  method: 'get',
});
// 租户id下拉
export const getTenantIdListAPI = (appCode) => request({
  // url: '/api/blade-system/search/getHospitals',
  url:'/api/blade-system/app/getAppTententList',
  method: 'get',
  params: {
    appCode,
  }

});

// 登记接口
export const loginByUsername = (tenantId, deptId, roleId, username, password, type, key, code) => request({
  url: '/api/blade-auth/oauth/token',
  method: 'post',
  headers: {
    'Captcha-Key': key,
    'Captcha-Code': code,
    'Tenant-Id': tenantId,
    'Dept-Id': deptId,
    'Role-Id': roleId,

  },
  params: {
    tenantId,
    username,
    password,
    grant_type: 'captcha',
    scope: 'all',
    type,
  },
});

// 刷新token
export const refreshToken = (tenantId, token) => request({
  url: '/api/blade-auth/oauth/token',
  method: 'post',
  headers: {
    'Captcha-Key': 'saber',
    'Tenant-Id': tenantId,
  },
  params: {
    refresh_token: token,
    grant_type: 'refresh_token',
    scope: 'all',
  },
});
