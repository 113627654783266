import Vue from 'vue';
import VueRouter from 'vue-router';
// import Login from '@/views/login'
import Home from '@/views/home';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      //   // redirect: '/critical'
      // redirect: '/bindingOfMobile'
      // redirect: '/online'
      // redirect: '/oauth',
      // redirect: '/login',
      // redirect: '/patients',
      //  redirect: '/tab'
      redirect: '/menu',
      // redirect:'/selectHospital'
    },
    {
      path: '/redirect',
      name: 'redirect',
      component: () => import('@/views/oauth/redirect.vue'),
    },

    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/login/index.vue'),
    },

    {
      path: '/oauth',
      name: '企微授权',
      component: () => import('@/views/oauth/index.vue'),
    },
    {
      path: '/selectHospital',
      name: '医院',
      component: () => import('@/views/selectHospital/index.vue'),
    },
    {
      path: '/bindingOfMobile',
      name: '手机号登录',
      component: () => import('@/views/bindingOfMobile/index.vue'),
    },
    {
      path: '/menu',
      name: '菜单列表',
      component: () => import('@/views/menu/index.vue'),
    },
    {
      path: '/critical',
      name: 'critical',
      component: () => import('@/views/critical/index.vue'),
    },
    {
      path: '/criticalList',
      name: 'criticalList',
      component: () => import('@/views/critical/list.vue'),
    },
    {
      // path: '/criticalInfo/:item',   //params传参
      path: '/criticalInfo',
      name: 'criticalInfo',
      component: () => import('@/views/critical/info.vue'),
    },
    {
      path: '/online',
      name: 'online',
      component: () => import('@/views/online/index.vue'),
    },
    // 门诊数据
    {
      path: '/outpatient',
      name: 'outpatient',
      component: () => import('@/views/online/outpatient.vue'),
    },
    // 出院数据
    {
      path: '/hospital',
      name: 'hospital',
      component: () => import('@/views/online/hospital.vue'),
    },
    // 入院数据
    {
      path: '/inHospital',
      name: 'inHospital',
      component: () => import('@/views/online/inHospital.vue'),
    },
    // 手术
    {
      path: '/surgery',
      name: 'surgery',
      component: () => import('@/views/online/surgery.vue'),
    },
    // 手术室
    {
      path: '/operating',
      name: 'operating',
      component: () => import('@/views/online/operating.vue'),
    },
    {
      path: '/numbers',
      name: 'numbers',
      component: () => import('@/views/online/numbers.vue'),
    },
    // 在院患者
    {
      path: '/patients',
      name: 'patients',
      component: () => import('@/views/patients/index.vue'),
    },
    {
      path: '/patientsInfo',
      name: 'patientsInfo',
      component: () => import('@/views/patients/info.vue'),
    },
    {
      path: '/jcInfo',
      name: 'jcInfo',
      component: () => import('@/views/patients/jcInfo.vue'),
    },
    // 个人详情
    {
      path: '/userInfo',
      name: 'userInfo',
      component: () => import('@/views/patients/userInfo.vue'),
    },
    // 文本
    {
      path: '/textInfo',
      name: 'textInfo',
      component: () => import('@/views/online/textInfo.vue'),
    },
    {
      path: '/home',
      component: Home,
    },

    {
      path: '/yaoshi',
      redirect: '/yaoshi/audit',
      name: '药师',
      component: () => import('@/views/yaoshi/entry.vue'),
      children: [
        {
          path: '/yaoshi/audit',
          component: () => import('@/views/yaoshi/audit.vue'),
        },
        {
          path: '/yaoshi/list',
          component: () => import('@/views/yaoshi/list.vue'),
        },
        {
          path: '/yaoshi/recipeDetail',
          component: () => import('@/views/yaoshi/recipeDetail.vue'),
        },
      ],
    },
    {
      path: '/yisheng',
      redirect: '/yisheng/list',
      name: '医生',
      component: () => import('@/views/yisheng/entry.vue'),
      children: [
        {
          path: '/yisheng/list',
          component: () => import('@/views/yisheng/list.vue'),
          meta: {
            tabbar: true,
            title: '在线咨询',
          },
        },
        {
          path: '/yisheng/consult',
          component: () => import('@/views/yisheng/consult.vue'),
          meta: {
            tabbar: false,
            title: '图文接诊',
          },
        },
        {
          path: '/yisheng/patient',
          component: () => import('@/views/yisheng/patient.vue'),
          meta: {
            tabbar: false,
            title: '病人信息',
          },
        },
        {
          path: '/yisheng/chat',
          component: () => import('@/views/yisheng/chat.vue'),
          meta: {
            tabbar: false,
            title: '图文咨询',
          },
        },
        {
          path: '/yisheng/diagnosis',
          component: () => import('@/views/yisheng/diagnosis.vue'),
          meta: {
            tabbar: false,
            title: '开医嘱',
          },
        },
        {
          path: '/yisheng/video',
          component: () => import('@/views/yisheng/video.vue'),
          meta: {
            tabbar: false,
            title: '视频接诊',
          },
        },
        {
          path: '/yisheng/videoDetail',
          component: () => import('@/views/yisheng/videoDetail.vue'),
          meta: {
            tabbar: false,
            title: '视频接诊',
          },
        },
        {
          path: '/yisheng/trending',
          component: () => import('@/views/yisheng/trending.vue'),
          meta: {
            tabbar: true,
            title: '收入统计',
          },
        },
        {
          path: '/yisheng/user',
          component: () => import('@/views/yisheng/user.vue'),
          meta: {
            tabbar: true,
            title: '我的',
          },
        },
      ],
    },
    {
      path: '/reportMdt',
      name: 'MDT',
      component: () => import('@/components/reportMDT/reportMDT.vue'),
    },
    {
      path: '/reportMdtMyApplication',
      name: 'MDT我的申请',
      component: () => import('@/components/reportMDT/myApplication.vue'),
    },
    {
      path: '/reportMdtRelevantToMe',
      name: 'MDT与我相关',
      component: () => import('@/components/reportMDT/relevantToMe.vue'),
    },

    {
      path: '/reportScreenage',
      name: '远程影像',
      component: () => import('@/components/reportScreenage/reportScreenage.vue'),
    },
    {
      path: '/reportScreenageMyApplication',
      name: '远程影像我的申请',
      component: () => import('@/components/reportScreenage/myApplication.vue'),
    },
    {
      path: '/reportScreenageApplyToMe',
      name: '远程影像向我申请',
      component: () => import('@/components/reportScreenage/applyToMe.vue'),
    },
    {
      path: '/reportOutpatient',
      name: '远程门诊',
      component: () => import('@/components/reportOutpatient/reportOutpatient.vue'),
    },
    {
      path: '/reportOutpatientMyApplication',
      name: '远程门诊我的申请',
      component: () => import('@/components/reportOutpatient/myApplication.vue'),
    },
    {
      path: '/reportOutpatientApplyToMe',
      name: '远程门诊向我申请',
      component: () => import('@/components/reportOutpatient/applyToMe.vue'),
    },
    {
      path: '/reportConsultant',
      name: '远程会诊',
      component: () => import('@/components/reportConsultant/reportConsultant.vue'),
    },
    {
      path: '/reportConsultantMyApplication',
      name: '远程会诊我的申请',
      component: () => import('@/components/reportConsultant/myApplication.vue'),
    },
    {
      path: '/reportConsultantApplyToMe',
      name: '远程会诊向我申请',
      component: () => import('@/components/reportConsultant/applyToMe.vue'),
    },

    {
      path: '/reportPathology',
      name: '远程病理',
      component: () => import('@/components/reportPathology/reportPathology.vue'),
    },
    {
      path: '/reportPathologyMyApplication',
      name: '远程病理我的申请',
      component: () => import('@/components/reportPathology/myApplication.vue'),
    },
    {
      path: '/reportPathologyApplyToMe',
      name: '远程病理向我申请',
      component: () => import('@/components/reportPathology/applyToMe.vue'),
    },

    {
      path: '/reportDualReferral',
      name: '双向转诊',
      component: () => import('@/components/reportDualReferral/reportDualReferral.vue'),
    },
    {
      path: '/outHospital',
      name: '本院转出',
      component: () => import('@/components/reportDualReferral/outHospital.vue'),
    },
    {
      path: '/inHospital',
      name: '本院转入',
      component: () => import('@/components/reportDualReferral/inHospital.vue'),
    },
    {
      path: '/workingSchedule',
      name: '排班查询',
      component: () => import('@/views/workingSchedule/index.vue'),
    },
    {
      path: '/doctorAdvice',
      name: '查看医嘱',
      component: () => import('@/views/doctorAdvice/index.vue'),
    },
    {
      path: '/doctorAdviceList',
      name: '查看医嘱',
      component: () => import('@/views/doctorAdvice/list.vue'),
    },
    {
      path: '/doctorAdviceDetail',
      name: '查看医嘱',
      component: () => import('@/views/doctorAdvice/detail.vue'),
    },

    {
      path: '/electronicQuery',
      name: '手机阅片',
      component: () => import('@/views/electronicQuery/index.vue'),
    },
    {
      path: '/electronicQueryList',
      name: '手机阅片列表',
      component: () => import('@/views/electronicQuery/list.vue'),
    },
    {
      path: '/electronicQueryDetail',
      name: '手机阅片详情',
      component: () => import('@/views/electronicQuery/detail.vue'),
    },
    {
      path: '/electronicView',
      name: '手机阅片',
      component: () => import('@/views/electronicQuery/electronicView.vue'),
    },

    {
      path: '/checkListQuery',
      name: '检查单查询',
      component: () => import('@/views/checkListQuery/index.vue'),
    },
    {
      path: '/checkListQueryList',
      name: '检查单列表',
      component: () => import('@/views/checkListQuery/list.vue'),
    },
    {
      path: '/checkListQueryDetail',
      name: '检查单详情',
      component: () => import('@/views/checkListQuery/detail.vue'),
    },
    // {
    //   path: '/checkListQueryView',
    //   name: '检查单线上报告',
    //   component: () => import('@/views/checkListQuery/electronicView.vue'),
    // },


    {
      path: '/inspectionQuery',
      name: '检验单查询',
      component: () => import('@/views/inspectionQuery/index.vue'),
    },
    {
      path: '/inspectionQueryList',
      name: '检验单列表',
      component: () => import('@/views/inspectionQuery/list.vue'),
    },
    {
      path: '/inspectionQueryDetail',
      name: '检验单详情',
      component: () => import('@/views/inspectionQuery/detail.vue'),
    },
    // {
    //   path: '/inspectionQueryView',
    //   name: '检验单线上报告',
    //   component: () => import('@/views/inspectionQuery/electronicView.vue'),
    // },


  ],
});

router.beforeEach((to, from, next) => {
  console.log(router,'router')
  console.log(to.path,'toPath')
  if (to.path === '/oauth' || to.path === '/selectHospital' || to.path === '/bindingOfMobile' || to.path === '/redirect' || to.path === '/login'|| to.path ==='/menu') {
    next();
  } else {
    const token = localStorage.getItem('token');
    if (token) {
      next();
    } else {
      // next('/oauth');
      next();
    }
  }
});

export default router;
