import { setToken, setRefreshToken, removeToken, removeRefreshToken } from '@/utils/token'
import { setStore, getStore } from '@/utils/localStorage'
import { validatenull } from '@/utils/validate'
import { Tos } from '@/utils/validate'
import md5 from 'js-md5'
import AES from "@/config/AES.js";
import { Toast } from "vant";
import { loginByUsername, loginBySocial, getUserAPI, logout, refreshToken, loginAPI, getTenantIdListAPI, getCaptcha } from '@/api/index'

const user = {
  state: {
    tenantId: getStore({ name: 'tenantId' }) || '',
    deptId: getStore({ name: 'deptId' }) || '',
    userInfo: getStore({ name: 'userInfo' }) || [],
    permission: getStore({ name: 'permission' }) || {},
    roles: [],
    menuId: {},
    menu: getStore({ name: 'menu' }) || [],
    menuAll: getStore({ name: 'menuAll' }) || [],
    token: getStore({ name: 'token' }) || '',
    refreshToken: getStore({ name: 'refreshToken' }) || ''
  },
  actions: {
    // 根据用户名登录
    LoginByUsername ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        loginByUsername(userInfo.tenantId, userInfo.deptId, userInfo.roleId, userInfo.username, AES.encrypt(userInfo.password), userInfo.type, userInfo.key, userInfo.code).then(res => {
          const data = res.data;
          if (data.error_description) {
            Toast.fail('data.error_description');
          } else {
            setStore({name: 'token', content: data.access_token})
            setStore({name: 'tenantId', content: userInfo.tenantId})
            // commit('SET_TOKEN', data.access_token)
            // commit('SET_REFRESH_TOKEN', data.refresh_token)
            // commit('SET_TENANT_ID', data.tenant_id)
            // commit('SET_DEPT_ID', data.dept_id)
            // commit('SET_USER_INFO', data)
            // commit('DEL_ALL_TAG')
            // commit('CLEAR_LOCK')
          }
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 根据手机号登录
    LoginByPhone ({ commit }, userInfo) {
      return new Promise((resolve) => {
        loginByUsername(userInfo.phone, userInfo.code).then(res => {
          const data = res.data.data
          commit('SET_TOKEN', data);
          // commit('DEL_ALL_TAG')
          // commit('CLEAR_LOCK')
          resolve()
        })
      })
    },
    // 根据第三方信息登录
    // LoginBySocial ({ commit }, userInfo) {
    //   return new Promise((resolve) => {
    //     loginBySocial(userInfo.tenantId, userInfo.source, userInfo.code, userInfo.state).then(res => {
    //       const data = res.data
    //       if (data.error_description) {
    //         Message({
    //           message: data.error_description,
    //           type: 'error'
    //         })
    //       } else {
    //         commit('SET_TOKEN', data.access_token)
    //         commit('SET_REFRESH_TOKEN', data.refresh_token)
    //         commit('SET_USER_INFO', data)
    //         // commit('DEL_ALL_TAG')
    //         // commit('CLEAR_LOCK')
    //       }
    //       resolve()
    //     })
    //   })
    // },
    // 获取用户信息
    GetUserInfo ({ commit }) {
      return new Promise((resolve, reject) => {
        getUserAPI().then((res) => {
          const data = res.data.data
          commit('SET_ROLES', data.roles);
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      })
    },
    // 刷新token
    refreshToken ({ state, commit }) {
      window.console.log('handle refresh token')
      return new Promise((resolve, reject) => {
        refreshToken(state.refreshToken, state.tenantId).then(res => {
          const data = res.data
          commit('SET_TOKEN', data.access_token)
          commit('SET_REFRESH_TOKEN', data.refresh_token)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 登出
    // LogOut ({ commit }) {
    //   return new Promise((resolve, reject) => {
    //     logout().then(() => {
    //       commit('SET_TOKEN', '')
    //       commit('SET_MENU', [])
    //       commit('SET_MENU_ALL_NULL', [])
    //       commit('SET_ROLES', [])
    //       commit('SET_TAG_LIST', [])
    //       // commit('DEL_ALL_TAG')
    //       // commit('CLEAR_LOCK')
    //       removeToken()
    //       removeRefreshToken()
    //       resolve()
    //     }).catch(error => {
    //       reject(error)
    //     })
    //   })
    // },
    // 注销session
    FedLogOut ({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        commit('SET_MENU_ALL_NULL', [])
        commit('SET_MENU', [])
        commit('SET_ROLES', [])
        commit('SET_TAG_LIST', [])
        // commit('DEL_ALL_TAG')
        // commit('CLEAR_LOCK')
        removeToken()
        removeRefreshToken()
        resolve()
      })
    }

  },
  mutations: {
    SET_TOKEN: (state, token) => {
      setToken(token)
      state.token = token
      setStore({ name: 'token', content: state.token })
    },
    SET_MENU_ID (state, menuId) {
      state.menuId = menuId
    },
    SET_MENU_ALL: (state, menuAll) => {
      const menu = state.menuAll
      menuAll.forEach(ele => {
        if (!menu.find(item => item.label === ele.label && item.path === ele.path)) {
          menu.push(ele)
        }
      })
      state.menuAll = menu
      setStore({ name: 'menuAll', content: state.menuAll })
    },
    SET_MENU_ALL_NULL: (state) => {
      state.menuAll = []
      setStore({ name: 'menuAll', content: state.menuAll })
    },
    SET_MENU: (state, menu) => {
      state.menu = menu
      setStore({ name: 'menu', content: state.menu })
    },
    SET_REFRESH_TOKEN: (state, refreshToken) => {
      setRefreshToken(refreshToken)
      state.refreshToken = refreshToken
      setStore({ name: 'refreshToken', content: state.refreshToken })
    },
    SET_TENANT_ID: (state, tenantId) => {
      state.tenantId = tenantId
      setStore({ name: 'tenantId', content: state.tenantId })
    },
    SET_DEPT_ID: (state, deptId) => {
      state.deptId = deptId
      setStore({ name: 'deptId', content: state.deptId })
    },
    SET_USER_INFO: (state, userInfo) => {
      if (validatenull(userInfo.avatar)) {
        userInfo.avatar = '/img/bg/img-logo.png'
      }
      state.userInfo = userInfo
      setStore({ name: 'userInfo', content: state.userInfo })
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_PERMISSION: (state, permission) => {
      const result = []

      function getCode (list) {
        list.forEach(ele => {
          if (typeof (ele) === 'object') {
            const chiildren = ele.children
            const code = ele.code
            if (chiildren) {
              getCode(chiildren)
            } else {
              result.push(code)
            }
          }
        })
      }     
    }
  }

}
export default user
