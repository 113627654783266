<template>
  <div>
    <!-- <router-view></router-view> -->
  <van-nav-bar
     title="医联体应用"
     left-text=""
      left-arrow
      @click-left="onClickLeft"
    />
  <van-grid :column-num="3" :border=false icon-size="48px" >
  <van-grid-item  icon="photo-o" icon-prefix="" text="MDT"      to='/reportMdt'/>
   <van-grid-item icon="photo-o" icon-prefix="" text="远程影像" to='/reportScreenage' />
   <van-grid-item icon="photo-o" icon-prefix="" text="远程门诊" to='/reportOutpatient'/>
   <van-grid-item icon="photo-o" icon-prefix="" text="远程会诊" to='/reportConsultant' />
   <van-grid-item icon="photo-o" icon-prefix="" text="远程病理" to='/reportPathology'/>
   <van-grid-item icon="photo-o" icon-prefix="" text="双向转诊" to='/reportDualReferral'/>
</van-grid>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  methods: {
    onClickLeft () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="less" scoped>
 .my-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    line-height: 150px;
    text-align: center;
    background-color: #39a9ed;
  }
  // /deep/.van-grid-item__text {
  //  font-size: 16px;
  // }
  // .iconfont{
  //   font-size: 48px;
  //   text-align: center;
  //   vertical-align: bottom;
  //   color: #1578F9;
  // }
  // /deep/.van-grid-item__content{
  //   height: 50px;
  // }
  // .van-grid-item{
  //   margin-bottom: 50px ;
  // }
</style>
