// 基于axios封装网络请求
import website from '@/config/website';
import theAxios from 'axios';
import { Toast } from 'vant';
// import { getToken } from '@/utils/token.js'
const axios = theAxios.create({
  baseUrl: '',
  timeout: 20000,
});
axios.interceptors.request.use((config) => {
  // eslint-disable-next-line max-len
  config.headers[website.tokenHeader] = localStorage.getItem('token');
  return config;
});
axios.interceptors.response.use((res) => {
  if (res.data.code === 200) {
    return res;
  }
  Toast.fail(res.data.msg);
  return Promise.reject(new Error(res.data.msg));
},
error => {
  Toast.clear();
  // localStorage.clear();
  if (error.response) {
    Toast.fail(error.response.data.msg);
    return Promise.reject(error);
  }
  error = error.toString();
  return Promise.reject(error);
});
export default ({ url, method = 'GET', params = {}, data = {} }) => axios({
  url,
  method,
  params,
  data,
  headers: {
    'Tenant-Id': website.tenantId,
    'Dept-Id': '',
    'Role-Id': '',
    'Captcha-Key': 'dd13c00783843d999514241964cd9489',
    Authorization: 'Basic c2FiZXI6c2FiZXJfc2VjcmV0',
    // 'Blade-Auth': bladeAuth,

  },
});

export const get = (url, params = {}, config = {}) => {
  if (!config.hideLoading) {
    Toast.loading({
      message: '加载中...',
      forbidClick: true,
      duration: 0,
      loadingType: 'spinner',
    });
  }
  return axios.get(url, {
    params,
    headers: {
      'Tenant-Id': website.tenantId,
      'Dept-Id': '',
      'Role-Id': '',
      'Captcha-Key': 'dd13c00783843d999514241964cd9489',
      Authorization: 'Basic c2FiZXI6c2FiZXJfc2VjcmV0',
      // 'Blade-Auth': bladeAuth,
    },
  }).then((res) => {
    if (!config.hideLoading) Toast.clear();
    if (res.data.code === 200) {
      return res.data;
    }
    return res;
  });
};

export const post = (url, data = {}, config = {}) => {
  if (!config.hideLoading) {
    Toast.loading({
      message: '加载中...',
      forbidClick: true,
      duration: 0,
      loadingType: 'spinner',
    });
  }
  return axios.post(url, data, {
    headers: {
      'Tenant-Id': website.tenantId,
      'Dept-Id': '',
      'Role-Id': '',
      'Captcha-Key': 'dd13c00783843d999514241964cd9489',
      Authorization: 'Basic c2FiZXI6c2FiZXJfc2VjcmV0',
      // 'Blade-Auth': bladeAuth,
    },
  }).then((res) => {
    if (!config.hideLoading) Toast.clear();
    if (res.data.code === 200) {
      return res.data;
    }
    return res;
  });
};
